import React, { FC } from "react";

import { css, useTheme } from "@emotion/react";

import { usePushToDataLayer } from "../../../../utils/analytics";
import Headline from "../../../shared/Headline";
import { IconPredefined } from "../../../shared/icons/Icon";
import { PredefinedIconType } from "../../../shared/icons/Icon/Predefined";
import Button from "../../../shared/NewForms/Button";
import RouteLink from "../RouteLink";
import type { FinderGasStation } from "../store";
import InfoWindow, { InfoWindowProps } from "./InfoWindow";
import * as styles from "./style.module.scss";

export type GasStationInfoWindowProps = {
    gasStation: FinderGasStation;
} & InfoWindowProps;

const GasStationInfoWindow: FC<GasStationInfoWindowProps> = ({
    gasStation,
    ...props
}) => {
    usePushToDataLayer(
        "gasStations:finder:infoWindow:open",
        {
            station: {
                type: "gasStation",
                id: `gasStation:${gasStation.id.value}`,
            },
        },
        [gasStation]
    );

    const theme = useTheme();

    const closeIconStyles = css`
        position: absolute;
        top: 20px;
        right: 20px;
    `;

    return (
        <InfoWindow {...props}>
            {gasStation !== null && (
                <>
                    <Headline>
                        {gasStation.name.replace(
                            /Automatenstation/,
                            "Automaten\u{00AD}station"
                        )}
                    </Headline>

                    <div className={styles.AddressWithIcon}>
                        <address>
                            <div>{gasStation.address.streetAddress}</div>

                            <div>
                                {gasStation.address.postalCode}{" "}
                                {gasStation.address.locality}
                            </div>

                            {gasStation.contactPoint.telephone && (
                                <div>
                                    Telefon: {gasStation.contactPoint.telephone}
                                </div>
                            )}
                        </address>

                        <div className={styles.AddressWithIcon__IconContainer}>
                            <RouteLink {...gasStation.address} />
                        </div>
                    </div>

                    <Button
                        color="redOutline"
                        to={`/tankstelle/${gasStation.slug}`}
                        data-gtag="gasStation:toGasStationSite"
                        fullWidth
                        state={{
                            from:
                                window.location.pathname +
                                window.location.search,
                        }}
                    >
                        Zur Tankstellenseite
                    </Button>
                </>
            )}
        </InfoWindow>
    );
};
export default GasStationInfoWindow;
