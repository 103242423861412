import React, { FC, ReactElement, useEffect, useState } from "react";

import ReactDOM from "react-dom";
import type { MarkerClustererProps } from "@react-google-maps/api";
import { MarkerClusterer as MarkerClusterer_ } from "@react-google-maps/api";
import type { ClusterIconStyle } from "@react-google-maps/marker-clusterer";

const renderHTML = async (child: ReactElement): Promise<string> => {
    const host = document.createDocumentFragment();
    await new Promise<void>((resolve) => {
        ReactDOM.render(child, host, resolve);
    });
    return host.firstElementChild?.outerHTML ?? "";
};

const encodeData = (contentType: `${string}/${string}`, data: string) =>
    `data:${contentType};base64,${btoa(data)}`;

const renderData = async (
    contentType: `${string}/${string}`,
    child: JSX.Element
) => encodeData(contentType, await renderHTML(child));

const image = (s: number) =>
    renderData(
        "image/svg+xml",
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={s}
            height={s}
            viewBox="0 0 74.316 93.089"
        >
            <defs>
                <filter
                    id="map-marker-solid"
                    x="0"
                    y="0"
                    width="74.316"
                    height="93.089"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.604" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="translate(-593.524 -1233.912)">
                <g transform="translate(-6554 -1561.121)">
                    <g
                        transform="matrix(1, 0, 0, 1, 7147.52, 2795.03)"
                        filter="url(#map-marker-solid)"
                    >
                        <path
                            d="M25.264,73.574C3.955,42.682,0,39.511,0,28.158a28.158,28.158,0,0,1,56.316,0c0,11.353-3.955,14.524-25.264,45.415a3.521,3.521,0,0,1-5.788,0Z"
                            transform="translate(9 6)"
                            fill="#e60028"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );

const mkStyles = (ss: ReadonlyArray<number>) =>
    Promise.all(
        ss.map(
            async (s): Promise<ClusterIconStyle> => ({
                url: await image(s),
                textColor: "#fff",
                width: s,
                height: s,
                anchorText: [-7, 0],
            })
        )
    );

const imageSizes = [48];

const MarkerClusterer: FC<MarkerClustererProps> = ({
    options: { styles: styles_, ...options } = {},
    ...props
}) => {
    const [styles, setStyles] = useState<ClusterIconStyle[]>();
    useEffect(() => {
        if (!styles_) {
            mkStyles(imageSizes).then(setStyles);
        }
    }, [styles_]);

    return styles ? (
        <MarkerClusterer_
            options={{
                styles: styles_ ?? styles,
                ...options,
            }}
            {...props}
        />
    ) : null;
};
export default MarkerClusterer;
