import React, { FC, useEffect, useMemo, useRef } from "react";

import * as Eq from "fp-ts/lib/Eq";
import * as num from "fp-ts/lib/number";
import * as Arr from "fp-ts/lib/ReadonlyArray";
import * as St from "fp-ts/lib/ReadonlySet";

import type { WindowLocation } from "@gatsbyjs/reach-router";
import { Button, Message } from "react-bulma-components";
import Helmet from "react-helmet";
import { useLoadScript } from "@react-google-maps/api";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import scrollIntoView from "scroll-into-view-if-needed";

import { googleMapsApiKey } from "../../../../config";
import { routeFinderFromWindowLocation } from "../../../shared/RouteLink/Route";
import Spinner from "../../../shared/Spinner";
import useGasStations, {
    FinderStationOrd,
    useFinderUpdateLocation,
} from "../store";
import * as AsyncData from "../../../../types/AsyncData";
import * as FuelType from "../types/FuelType";
import * as PaymentMethod from "../types/PaymentMethod";
import * as Property from "../types/Property";
import * as StationType from "../types/StationType";
import BottomBar from "./BottomBar";
import FinderMap from "./Map";
import ResetFilter from "./ResetFilter";
import * as styles from "./style.module.scss";

type ErrorProps = {
    id: string;
    onClose?: () => void;
};

const Error: FC<ErrorProps> = ({ id, children, onClose, ...props }) => (
    <Message
        role="alert"
        className={styles.MapError}
        color="danger"
        renderAs="div"
        {...{ id }}
        {...props}
    >
        <Message.Header>
            Es ist ein Fehler aufgetreten
            {onClose && (
                <Button
                    type="button"
                    aria-controls={id}
                    aria-label="Schließen"
                    remove
                    onClick={onClose}
                />
            )}
        </Message.Header>

        <Message.Body>{children}</Message.Body>
    </Message>
);

const SpinnerWrapper = styled.div`
    min-height: calc(100vh - 78px);
`;

export type FinderProps = {
    location: WindowLocation;
};

const eqFnRef = Eq.fromEquals<(...args: any[]) => any>((a, b) => a === b);
const StoreEq = Eq.struct({
    availableOptions: AsyncData.getEq_(
        Eq.struct({
            fuelTypes: St.getEq(FuelType.Ord),
            allProperties: St.getEq(Property.Ord),
            properties: St.getEq(Property.Ord),
            paymentMethods: St.getEq(PaymentMethod.Ord),
            gasStationTypes: St.getEq(StationType.Ord),
        })
    ),
    gasStations: AsyncData.getEq_(Arr.getEq(FinderStationOrd)),
    geolocation: AsyncData.getEq_(
        Eq.struct({
            lat: num.Eq,
            lng: num.Eq,
        })
    ),
    resetGeolocation: eqFnRef,
    loadGasStations: eqFnRef,
    resetMap: eqFnRef,
    resetFilter: eqFnRef,
    setLocation: eqFnRef,
    setFilterFromRoute: eqFnRef,
    saveLocation: eqFnRef,
});

const Finder: FC<FinderProps> = ({ location }) => {
    const libraries = useMemo(() => ["places"] as "places"[], []);
    const theme = useTheme();

    const {
        availableOptions,
        gasStations,
        geolocation,
        resetGeolocation,
        loadGasStations,
        resetMap,
        resetFilter,
        setLocation,
        setFilterFromRoute,
        saveLocation,
    } = useGasStations(
        (state) => ({
            availableOptions: state.availableOptions,
            gasStations: state.gasStations,
            geolocation: state.geolocation,
            resetGeolocation: state.resetGeolocation,
            loadGasStations: state.loadGasStations,
            resetMap: state.resetMap,
            resetFilter: state.resetFilter,
            setLocation: state.setLocation,
            setFilterFromRoute: state.setFilterFromRoute,
            saveLocation: state.saveLocation,
        }),
        (a, b) => StoreEq.equals(a, b)
    );

    useEffect(() => {
        // filters should persist when the user is redirected back.
        if (AsyncData.isLoaded(gasStations)) {
            resetFilter();
        }
        loadGasStations();
        resetMap();
    }, []);

    useEffect(() => {
        if (AsyncData.isLoaded(availableOptions) && location) {
            setFilterFromRoute(routeFinderFromWindowLocation(location));
        }
    }, [location, availableOptions, setLocation, setFilterFromRoute]);

    useFinderUpdateLocation();

    const onFinderSubmit = () => {
        saveLocation();

        if (mapRef.current) {
            scrollIntoView(mapRef.current, {
                block: "center",
                inline: "nearest",
            });
            mapRef.current.focus();
        }
    };

    const googleMaps = useLoadScript({
        googleMapsApiKey,
        libraries,
    });

    const loadingProgress = [
        googleMaps.isLoaded || Boolean(googleMaps.loadError),
        AsyncData.isDone(gasStations),
        AsyncData.isDone(availableOptions),
    ];
    const isLoading = !loadingProgress.every(Boolean);

    const spinnerId = "finder-spinner";
    const spinner = (
        <SpinnerWrapper>
            <Spinner
                id={spinnerId}
                max={loadingProgress.length}
                now={loadingProgress.filter(Boolean).length}
            />
        </SpinnerWrapper>
    );

    const mapRef = useRef<HTMLDivElement>();

    return (
        <div
            css={css`
                .legacyPage & {
                    font-family: var(--font-family-vito);
                    color: ${theme.colors.blue.toString()};
                }
            `}
            className={styles.Finder}
            aria-busy={isLoading}
            aria-describedby={isLoading ? spinnerId : undefined}
        >
            <Helmet>
                <title>Tankstellenfinder ▷ liegt immer auf Ihrem Weg</title>

                <meta
                    name="description"
                    content="Hoyer Tankstellenfinder ▷ Ganz einfach und bequem die nächste Hoyer Tankstelle finden"
                />
            </Helmet>

            {isLoading ? (
                spinner
            ) : googleMaps.loadError ? (
                <Error id="finder-error-googleMaps">
                    Google Maps konnte nicht geladen werden.
                </Error>
            ) : (
                AsyncData.foldLoading(
                    // This can't actually happen, but is required for totality
                    () => spinner,
                    () => (
                        <>
                            {AsyncData.isError(geolocation) && (
                                <Error
                                    id="finder-error-geolocation"
                                    onClose={resetGeolocation}
                                >
                                    Ihr aktueller Standort konnte nicht
                                    abgefragt werden.
                                </Error>
                            )}

                            <ResetFilter />

                            <BottomBar onFinderSubmit={onFinderSubmit} />

                            <FinderMap ref={mapRef} className={styles.Map} />
                        </>
                    ),
                    () => (
                        <Error id="finder-error-gasStations">
                            Die Tankstellendaten konnten nicht geladen werden;
                            bitte versuchen Sie es später erneut.
                        </Error>
                    ),
                    AsyncData.sequenceT(gasStations, availableOptions)
                )
            )}
        </div>
    );
};

export default Finder;
