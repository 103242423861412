/* global google */

import React, {
    FC,
    forwardRef,
    ForwardRefRenderFunction,
    useEffect,
    useMemo,
    useState,
} from "react";

import * as Eq from "fp-ts/lib/Eq";
import * as Arr from "fp-ts/lib/ReadonlyArray";

import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import MediaQuery from "react-responsive";
import {
    Circle,
    DirectionsRenderer,
    GoogleMap,
    Marker,
} from "@react-google-maps/api";
import type { Clusterer } from "@react-google-maps/marker-clusterer";

import ThemeProvider from "../../../Theme";
import useGasStations, {
    FinderGasStation,
    finderStationIsFinderTravisStation,
    FinderStationOrd,
    FinderTravisStation,
} from "../store";
import * as asyncData from "../../../../types/AsyncData";
import * as Station from "../types/Station";
import DurationInfoWindow from "./DurationInfoWindow";
import GasStationInfoWindow from "./GasStationInfoWindow";
import mapStyles from "./mapStyles";
import StationMarker, { StationMarkerProps } from "./Marker";
import CurrentLocationIcon from "./Marker/current-location.svg";
import RouteMarkerAIcon from "./Marker/route-a.svg";
import RouteMarkerBIcon from "./Marker/route-b.svg";
import MarkerClusterer from "./MarkerClusterer";
import TravisInfoWindow from "./TravisInfoWindow";

const containerStyle = {
    minHeight: "calc(100vh - 123px)",
};

export type GasStationMarkerProps = {
    gasStation: FinderGasStation;
    onInfoWindowClose: () => void;
} & Omit<StationMarkerProps, "onLoad" | "gasStation">;

export const GasStationMarker: FC<GasStationMarkerProps> = ({
    gasStation,
    selected,
    onInfoWindowClose,
    ...props
}) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    return (
        <>
            <StationMarker
                key={0}
                onLoad={setMarker}
                {...{ gasStation, selected }}
                {...props}
            />

            {selected && marker && (
                <GasStationInfoWindow
                    key={1}
                    anchor={marker}
                    onCloseClick={onInfoWindowClose}
                    {...{ gasStation }}
                />
            )}
        </>
    );
};

export type TravisStationMarkerProps = {
    gasStation: FinderTravisStation;
    onInfoWindowClose: () => void;
} & Omit<StationMarkerProps, "onLoad">;

const TravisStationMarker: FC<TravisStationMarkerProps> = ({
    gasStation,
    selected,
    onInfoWindowClose,
    ...props
}) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    return (
        <>
            <StationMarker
                key={0}
                onLoad={setMarker}
                {...{ gasStation, selected }}
                {...props}
            />

            {selected && marker && (
                <TravisInfoWindow
                    key={1}
                    anchor={marker}
                    onCloseClick={onInfoWindowClose}
                    {...{ gasStation }}
                />
            )}
        </>
    );
};

export type MarkersProps = {
    clusterer: Clusterer;
};

const eqFnRef = Eq.fromEquals<(...args: any[]) => any>((a, b) => a === b);
const getNullEq = <a,>(eq: Eq.Eq<a>): Eq.Eq<a | null> =>
    Eq.fromEquals((a, b) =>
        a === null
            ? b === null
                ? true
                : false
            : b === null
            ? false
            : eq.equals(a, b)
    );
const StoreEq = Eq.struct({
    gasStations: Arr.getEq(FinderStationOrd),
    filteredGasStations: Arr.getEq(FinderStationOrd),
    selectedGasStation: getNullEq(FinderStationOrd),
    selectGasStation: eqFnRef,
    unselectGasStation: eqFnRef,
});

const Markers: FC<MarkersProps> = ({ clusterer }) => {
    const {
        gasStations,
        filteredGasStations,
        selectedGasStation,
        selectGasStation,
        unselectGasStation,
    } = useGasStations(
        (state) => ({
            gasStations: asyncData.foldLoading(
                () => [],
                (a) => a,
                () => [],
                state.gasStations
            ),
            filteredGasStations: asyncData.foldLoading(
                () => [],
                (a) => a,
                () => [],
                state.filteredGasStations
            ),
            selectedGasStation: state.selectedGasStation,
            selectGasStation: state.selectGasStation,
            unselectGasStation: state.unselectGasStation,
        }),
        (a, b) => StoreEq.equals(a, b)
    );

    useEffect(() => clusterer.repaint(), [clusterer, filteredGasStations]);

    const node = useMemo(
        () => (
            <>
                {gasStations.map((gasStation) =>
                    finderStationIsFinderTravisStation(gasStation) ? (
                        <TravisStationMarker
                            key={`${gasStation.id.type}-${gasStation.id.value}`}
                            selected={
                                selectedGasStation?.id
                                    ? Station.IdOrd.equals(
                                          gasStation.id,
                                          selectedGasStation.id
                                      )
                                    : false
                            }
                            onClick={() => selectGasStation(gasStation)}
                            onInfoWindowClose={unselectGasStation}
                            visible={filteredGasStations.includes(gasStation)}
                            {...{ gasStation, clusterer }}
                        />
                    ) : (
                        <GasStationMarker
                            key={`${gasStation.id.type}-${gasStation.id.value}`}
                            selected={
                                selectedGasStation?.id
                                    ? Station.IdOrd.equals(
                                          gasStation.id,
                                          selectedGasStation.id
                                      )
                                    : false
                            }
                            onClick={() => selectGasStation(gasStation)}
                            onInfoWindowClose={unselectGasStation}
                            visible={filteredGasStations.includes(gasStation)}
                            {...{ gasStation, clusterer }}
                        />
                    )
                )}
            </>
        ),
        [
            clusterer,
            gasStations,
            filteredGasStations,
            selectedGasStation,
            selectGasStation,
            unselectGasStation,
        ]
    );

    return node;
};

export type FinderMapProps = JSX.IntrinsicElements["div"];

const FinderMap: ForwardRefRenderFunction<HTMLDivElement, FinderMapProps> = (
    props,
    ref
) => {
    const { map, location, filter, routeEnd, setMapCenter } = useGasStations(
        (state) => ({
            map: state.map,
            location: state.location,
            filter: state.filter,
            saveLocation: state.saveLocation,
            routeEnd: state.routeEnd,
            setMapCenter: state.setMapCenter,
        })
    );

    const [googleMap, setGoogleMap] = useState<google.maps.Map>();
    useEffect(() => {
        if (!googleMap) return;
        const formHost = document.createElement("div");
        ReactDOM.render(
            <MediaQuery minWidth={1024}>
                {/* This is rendered out-of-tree, so we need to specify the providers again. */}
                <IntlProvider locale="de" defaultLocale="de">
                    <ThemeProvider>
                        {/*<FinderForm
                            className={styles.MapFinderForm}
                            onSubmit={saveLocation}
                        />*/}
                    </ThemeProvider>
                </IntlProvider>
            </MediaQuery>,
            formHost
        );
        googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(formHost);
    }, [googleMap]);

    const directions = useMemo(
        () =>
            map.directions && (
                <>
                    <DirectionsRenderer
                        options={{
                            directions: map.directions,
                            suppressMarkers: true,
                        }}
                    />
                    {
                        // Technically map.directions being truthy implies these three conditions,
                        // but that isn't represented in the type
                        asyncData.isLoaded(location) &&
                            routeEnd &&
                            asyncData.isLoaded(routeEnd) && (
                                <>
                                    <Marker
                                        position={location.data.geo}
                                        icon={RouteMarkerAIcon}
                                    />
                                    <Marker
                                        position={routeEnd.data.geo}
                                        icon={RouteMarkerBIcon}
                                    />
                                    {map.directions &&
                                        map.directions.routes &&
                                        map.directions.routes.length > 0 && (
                                            <DurationInfoWindow
                                                directions={map.directions}
                                            />
                                        )}
                                </>
                            )
                    }
                </>
            ),
        [map.directions, location, routeEnd]
    );

    const clusterer = useMemo(
        () => (
            <MarkerClusterer ignoreHidden={true}>
                {(clusterer) => <Markers {...{ clusterer }} />}
            </MarkerClusterer>
        ),
        []
    );

    return (
        <>
            {location.state !== "loading" && (
                <div {...{ ref }} className="map" {...props}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        options={{
                            styles: mapStyles,
                            mapTypeControl: true,
                            mapTypeControlOptions: {
                                position: google.maps.ControlPosition.TOP_RIGHT,
                            },
                        }}
                        onIdle={() => {
                            const center = googleMap?.getCenter?.();
                            const zoom = googleMap?.getZoom?.();

                            if (window && center && zoom != null) {
                                setMapCenter(
                                    {
                                        lat: center.lat(),
                                        lng: center.lng(),
                                    },
                                    zoom
                                );
                            }
                        }}
                        center={map.center}
                        zoom={map.zoom}
                        onLoad={setGoogleMap}
                    >
                        {clusterer}

                        {directions}

                        {asyncData.isLoaded(location) && !map.directions && (
                            <Marker
                                key={`userCoordinates`}
                                position={location.data.geo}
                                icon={CurrentLocationIcon}
                            />
                        )}

                        {asyncData.isLoaded(location) &&
                            filter.circumscribedArea.radius && (
                                <Circle
                                    center={location.data.geo}
                                    radius={
                                        filter.circumscribedArea.radius.value *
                                        1000
                                    }
                                    options={{
                                        strokeColor: "#3273dc",
                                        fillColor: "#3273dc",
                                    }}
                                />
                            )}
                    </GoogleMap>
                </div>
            )}
        </>
    );
};
export default forwardRef(FinderMap);
